<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row>
        <v-col
          v-if="(permisos.includes('contrate_flights:create') || permisos.includes('*')) && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <!-- <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.selectVuelo') }}</strong>
          <!--<v-select
            v-model="model.aerolinea_id"
            class="pt-2"
            :items="aerolineas"
            item-text="name"
            item-value="id"
            :label="$t('lbl.operador')"
            outlined
            dense
            hide-details
            @change="changeAerolinea"
          ></v-select>-->
          <v-autocomplete
            v-model="model.aerolinea_id"
            class="pt-2"
            :items="itemsAerolinea"
            :search-input.sync="searchAerolinea"
            hide-details
            hide-selected
            :label="$t('lbl.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            single-line
            @change="changeAerolinea"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong style="color: #FFFFFF">.</strong>
          <v-text-field
            ref="numero_de_vuelo"
            v-model="model.numero_de_vuelo"
            class="pt-2"
            :label="$t('lbl.numeroVuelo')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <strong>{{ $t('lbl.frecuency') }}</strong>
          <v-sheet class="py-2">
            <v-slide-group
              v-model="model.frecuencia"
              multiple
            >
              <v-slide-item
                v-for="(sema, index) in semanas"
                v-slot="{ active, toggle }"
                :key="index"
                class="px-1"
              >
                <v-btn
                  class=""
                  :input-value="active"
                  active-class="primary white--text"
                  depressed
                  rounded
                  small
                  @click="toggle"
                >
                  {{ sema }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="model.proveedor_id"
            class="pt-2"
            :items="itemsProveedor"
            :search-input.sync="searchProveedor"
            hide-details
            hide-selected
            :label="$t('lbl.proveedor')"
            outlined
            dense
            item-text="name_comercial"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.proveedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name_comercial"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="model.identificador"
            :label="$t('lbl.identificador')"
            class="pt-2"
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <strong>{{ $t('lbl.ruta') }}</strong>
          <v-autocomplete
            v-model="model.origen"
            :items="itemsOrigen"
            :search-input.sync="searchOrigen"
            hide-details
            hide-selected
            :label="$t('lbl.origen')"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.origenSearch') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <strong style="color: #FFFFFF">{{ $t('lbl.ruta') }}</strong>
          <v-autocomplete
            v-model="model.destino"
            :items="itemsDestino"
            :search-input.sync="searchDestino"
            hide-details
            hide-selected
            :label="$t('lbl.destino')"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.destinoSearch') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="
              model.aerolinea_id ||
                model.proveedor_id ||
                model.identificador ||
                model.numero_de_vuelo ||
                model.frecuencia ||
                model.origen ||
                model.destino
            "
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-btn
            :loading="loading"
            color="primary"
            @click="searchAll()"
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.contratos') }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.proveedor') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.vuelo') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.origen') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.destino') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.identificador') }}
            </th>
            <th></th>
            <th
              width="150px"
              class="text-uppercase"
            >
              {{ $t('lbl.frecuency') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.departure') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.arrival') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.adl') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.mnr') }}
            </th>
            <th
              v-if="
                permisos.includes('contrate_flights:edit') ||
                  permisos.includes('contrate_flights:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
              width="150px"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              <!--<span v-if="iten.proveedor_id != null">
                {{ iten.proveedor.name_comercial }}
              </span>-->
              <span v-if="user.nivel <= 0">
                <span v-if="iten.operador === 1111">
                  <span v-if="iten.proveedor_id != null">
                    {{ iten.proveedor.name_comercial }}
                  </span>
                </span>
                <span v-else>
                  {{ iten.operador }}
                </span>
              </span>
              <span v-else>
                <span v-if="iten.proveedor_id != null">
                  {{ iten.proveedor.name_comercial }}
                </span>
              </span>
            </td>
            <td>{{ iten.flight.numero_de_vuelo }}</td>
            <td>
              <span v-if="iten.flight.origen !== null">
                {{ iten.flight.origen.destino.iataComodin }}
              </span>
            </td>
            <td>
              <span v-if="iten.flight.destino !== null">
                {{ iten.flight.destino.destino.iataComodin }}
              </span>
            </td>
            <td>{{ iten.identificador }}</td>
            <td>
              <v-btn
                v-if="iten.data_clases.length > 1"
                icon
                @click="showItem(index)"
              >
                <v-icon>{{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}</v-icon>
              </v-btn>
            </td>
            <td>
              <template v-if="(showRow && index === indexRow) || iten.data_clases.length <= 1">
                <!--<p
                  v-for="(clase, j) in iten.data_clases"
                  :key="j"
                  class="pt-2"
                >
                  <span v-if="clase.frecuencia.modelSemanas.length > 0">
                    <span v-if="clase.frecuencia.tab === 0">
                      {{ $t('lbl.all') }}
                    </span>
                    <span v-else-if="clase.frecuencia.tab === 1">
                      <span
                        v-for="(frecuency, f) in clase.frecuencia.modelSemanas"
                        :key="`f${f}`"
                      >
                        <span v-if="frecuency === 0">Domingo</span>
                        <span v-else-if="frecuency === 1">Lunes</span>
                        <span v-else-if="frecuency === 2">Martes</span>
                        <span v-else-if="frecuency === 3">Miércoles</span>
                        <span v-else-if="frecuency === 4">Jueves</span>
                        <span v-else-if="frecuency === 5">Viernes</span>
                        <span v-else-if="frecuency === 6">Sábado</span>
                        <span v-if="f !== clase.frecuencia.modelSemanas.length - 1">,</span>
                      </span>
                    </span><br>
                  </span>
                </p>-->
                <v-tooltip
                  v-for="(clase, j) in iten.data_clases"
                  :key="j"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span v-if="clase.frecuencia.tab === 0">
                        {{ $t('lbl.all') }}
                      </span>
                      <span v-else-if="clase.frecuencia.tab === 1">
                        <span v-if="clase.frecuencia.modelSemanas.length > 0">
                          <span v-if="clase.frecuencia.modelSemanas[0] === 0">Domingo</span>
                          <span v-else-if="clase.frecuencia.modelSemanas[0] === 1">Lunes</span>
                          <span v-else-if="clase.frecuencia.modelSemanas[0] === 2">Martes</span>
                          <span v-else-if="clase.frecuencia.modelSemanas[0] === 3">Miércoles</span>
                          <span v-else-if="clase.frecuencia.modelSemanas[0] === 4">Jueves</span>
                          <span v-else-if="clase.frecuencia.modelSemanas[0] === 5">Viernes</span>
                          <span v-else-if="clase.frecuencia.modelSemanas[0] === 6">Sábado</span>
                        </span>
                      </span>
                      <span v-else-if="clase.frecuencia.tab === 2">
                        <span v-if="clase.frecuencia.dates.length > 0">
                          {{ clase.frecuencia.dates[0] }}
                        </span>
                      </span>
                      <br />
                    </p>
                  </template>
                  <span v-if="clase.frecuencia.tab === 0">
                    {{ $t('lbl.all') }}
                  </span>
                  <span v-else-if="clase.frecuencia.tab === 1">
                    <span v-if="clase.frecuencia.modelSemanas.length > 0">
                      <span
                        v-for="(frecuency, f) in clase.frecuencia.modelSemanas"
                        :key="`f${f}`"
                      >
                        <span v-if="frecuency === 0">Domingo</span>
                        <span v-else-if="frecuency === 1">Lunes</span>
                        <span v-else-if="frecuency === 2">Martes</span>
                        <span v-else-if="frecuency === 3">Miércoles</span>
                        <span v-else-if="frecuency === 4">Jueves</span>
                        <span v-else-if="frecuency === 5">Viernes</span>
                        <span v-else-if="frecuency === 6">Sábado</span>
                        <span v-if="f !== clase.frecuencia.modelSemanas.length - 1"><br /></span>
                      </span>
                    </span>
                  </span>
                  <span v-else-if="clase.frecuencia.tab === 2">
                    <span v-if="clase.frecuencia.dates.length > 0">
                      <span
                        v-for="(frecuency, f) in clase.frecuencia.dates"
                        :key="`f${f}`"
                      >
                        <span>{{ frecuency }}</span>
                        <span v-if="f !== clase.frecuencia.dates.length - 1"><br /></span>
                      </span>
                    </span>
                  </span>
                </v-tooltip>
              </template>
            </td>
            <td>
              <template v-if="(showRow && index === indexRow) || iten.data_clases.length <= 1">
                <v-tooltip
                  v-for="(clas, j) in iten.data_clases"
                  :key="j"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>
                        <span>{{ checkIn(clas.frecuencia, iten)[0].hora }}</span> </span><br />
                    </p>
                  </template>
                  <span
                    v-for="(check, i) in checkIn(clas.frecuencia, iten)"
                    :key="i"
                  >
                    <span v-if="check.dia === 0">Domingo:</span>
                    <span v-else-if="check.dia === 1">Lunes: </span>
                    <span v-else-if="check.dia === 2">Martes: </span>
                    <span v-else-if="check.dia === 3">Miércoles: </span>
                    <span v-else-if="check.dia === 4">Jueves: </span>
                    <span v-else-if="check.dia === 5">Viernes: </span>
                    <span v-else-if="check.dia === 6">Sábado: </span>
                    {{ check.hora }}
                    <br />
                  </span>
                </v-tooltip>
              </template>
            </td>
            <td>
              <template v-if="(showRow && index === indexRow) || iten.data_clases.length <= 1">
                <v-tooltip
                  v-for="(clas, j) in iten.data_clases"
                  :key="j"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>
                        <span>{{ arrivalFrec(clas.frecuencia, iten)[0].hora }}</span> </span><br />
                    </p>
                  </template>
                  <span
                    v-for="(check, i) in arrivalFrec(clas.frecuencia, iten)"
                    :key="i"
                  >
                    <span v-if="check.dia === 0">Domingo:</span>
                    <span v-else-if="check.dia === 1">Lunes: </span>
                    <span v-else-if="check.dia === 2">Martes: </span>
                    <span v-else-if="check.dia === 3">Miércoles: </span>
                    <span v-else-if="check.dia === 4">Jueves: </span>
                    <span v-else-if="check.dia === 5">Viernes: </span>
                    <span v-else-if="check.dia === 6">Sábado: </span>
                    {{ check.hora }}
                    <br />
                  </span>
                </v-tooltip>
              </template>
            </td>
            <td>
              <template v-if="(showRow && index === indexRow) || iten.data_clases.length <= 1">
                <v-tooltip
                  v-for="(clas, j) in iten.data_clases"
                  :key="j"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span v-if="clas.tarifas.length > 0">
                        <span> ${{ menorPrice(clas.tarifas, 'price_adult') }} </span><br />
                      </span>
                    </p>
                  </template>
                  <span>{{ $t('lbl.price_min') }}</span>
                </v-tooltip>
              </template>
            </td>
            <td>
              <template v-if="(showRow && index === indexRow) || iten.data_clases.length <= 1">
                <v-tooltip
                  v-for="(clas, j) in iten.data_clases"
                  :key="j"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span v-if="clas.tarifas.length > 0">
                        <span> ${{ menorPrice(clas.tarifas, 'price_child') }} </span><br />
                      </span>
                    </p>
                  </template>
                  <span>{{ $t('lbl.price_min') }}</span>
                </v-tooltip>
              </template>
            </td>
            <td
              v-if="
                permisos.includes('contrate_flights:list') ||
                  permisos.includes('contrate_flights:edit') ||
                  permisos.includes('contrate_flights:destroy') ||
                  permisos.includes('*')
              "
            >
              <template v-if="(showRow && index === indexRow) || iten.data_clases.length <= 1">
                <!--<span
                  v-for="(tarifa, j) in iten.data_clases"
                  :key="j"
                >
                  <v-tooltip
                    v-if="permisos.includes('contrate_flights:edit') ||
                      permisos.includes('*')"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="editItem(iten, j)"
                      >
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip
                    v-if="permisos.includes('contrate_flights:edit') ||
                      permisos.includes('*')"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="cloneItem(iten, j)"
                      >
                        <v-icon>{{ icons.mdiEyePlus }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.clone') }}</span>
                  </v-tooltip>

                  <v-tooltip
                    v-if="permisos.includes('contrate_flights:edit') ||
                      permisos.includes('*')"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItem(iten, j)"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.delete') }}</span>
                  </v-tooltip>
                  <br>
                </span>-->
                <!--<span v-if="iten.data_clases.length === 0">
                  <v-tooltip
                    v-if="permisos.includes('contrate_flights:destroy') ||
                      permisos.includes('*')"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteContrateItem(iten)"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.delete') }}</span>
                  </v-tooltip>
                </span> -->

                <v-tooltip
                  v-if="
                    (permisos.includes('contrate_flights:edit') || permisos.includes('*')) && iten.nivel === user.nivel
                  "
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItemContrate(iten)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('contrate_flights:list') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="seeItem(iten)"
                    >
                      <v-icon>{{ icons.mdiEye }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.see') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="
                    (permisos.includes('contrate_flights:destroy') || permisos.includes('*')) &&
                      iten.nivel === user.nivel
                  "
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteContrateItem(iten)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteFrecuencia', { item: '' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MOSTRAR DELETE CONTRATE-->
    <v-dialog
      v-model="isDialogVisibleDeleteContrate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteContrate') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDeleteContrate = !isDialogVisibleDeleteContrate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDeleteContrate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEyePlus,
  mdiMenuRight,
  mdiMenuLeft,
  mdiMenuDown,
  mdiEraserVariant,
  mdiEye,
} from '@mdi/js'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEyePlus,
        mdiMenuRight,
        mdiMenuLeft,
        mdiMenuDown,
        mdiEraserVariant,
        mdiEye,
      },
      showRow: false,
      indexRow: -1,
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteContrate: false,
      item: {},
      itemId: null,
      itemPosTarifa: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),

      model: {},
      aerolineas: [],
      itemsAerolinea: [],
      searchAerolinea: null,
      numeros_vuelos: [],
      semanas: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      isLoading: true,
      user: {},
      offline: false,
    }
  },
  watch: {
    searchAerolinea(val) {
      this.filterAerolinea(val.toLowerCase())
    },
    searchOrigen(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOrigen(val.toLowerCase())
      } else {
        this.itemsOrigen = []
      }
    },
    searchDestino(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  created() {
    this.profile()
    this.getAerolineas()
    this.getProveedores()

    // this.loadAll()
    this.load()

    this.semanas.push('D')
    this.semanas.push('L')
    this.semanas.push('M')
    this.semanas.push('X')
    this.semanas.push('J')
    this.semanas.push('V')
    this.semanas.push('S')
  },
  methods: {
    filterAerolinea(v) {
      this.itemsAerolinea = []
      if (v === '') {
        this.itemsAerolinea = []
      } else {
        this.itemsAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    filterOrigen(v) {
      this.itemsOrigen = []
      if (v === '') {
        this.itemsOrigen = []
      } else {
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsOrigen.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsDestino.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false
        })
    },
    load() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('contrate_flights/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            // this.items = res.data.data
            // PARA ORDERNAR BY PROVEEDOR
            this.items = res.data.data.sort((a, b) => {
              let OrderA = a.identificador
              if (this.user.nivel <= 0) {
                if (a.operador === 1111) {
                  OrderA = a.proveedor.name_comercial
                } else {
                  OrderA = a.operador
                }
              } else {
                OrderA = a.proveedor.name_comercial
              }

              let OrderB = b.identificador
              if (this.user.nivel <= 0) {
                if (b.operador === 1111) {
                  OrderB = b.proveedor.name_comercial
                } else {
                  OrderB = b.operador
                }
              } else {
                OrderB = b.proveedor.name_comercial
              }

              const x = OrderA
              const y = OrderB

              if (x > y) {
                return 1
              }
              if (x < y) {
                return -1
              }

              return 0
            })

            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.loadAll())
    },
    searchAll() {
      this.pagination.current = 1
      this.load()
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: 10000,
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('contrate_flights/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res)
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    getProveedores() {
      this.axios
        .post('proveedors/byproduct', { slug: 'vuelos' }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    getAerolineas() {
      this.axios
        .get(`nom_aerolines?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aerolineas = res.data.data
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      /* if (this.model.numero_de_vuelo) {
        this.axios.get(`flight/by/${this.model.numero_de_vuelo}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        }).then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
            this.$toast.error(this.$t('msg.noNumberFlight', { numero: this.model.numero_de_vuelo }))
          } else {
            sessionStorage.setItem('flight-aerolinea-id', res.data.data.data.aerolinea_id)
            sessionStorage.setItem('flight-numero-vuelo', res.data.data.data.numero_de_vuelo)

            sessionStorage.removeItem('flight-contrate-id')
            sessionStorage.removeItem('flight-contrate-pos-tarifa')
            sessionStorage.removeItem('flight-contrate-tarifa-edit')
            this.$router.push({ name: 'flights-contrate-update' })
          }
        })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.$toast.error(this.$t('msg.selectFlight'))
      } */
      sessionStorage.removeItem('flight-contrate-show')
      sessionStorage.removeItem('flight-contrate-id')
      sessionStorage.removeItem('flight-contrate-pos-tarifa')
      sessionStorage.removeItem('flight-contrate-tarifa-edit')
      this.$router.push({ name: 'flights-contrate-update' })
    },
    editItem(iten, posTarifa) {
      this.$store.state.app.loadEscalas = true
      sessionStorage.setItem('flight-contrate-id', iten.id)
      sessionStorage.setItem('flight-contrate-pos-tarifa', posTarifa)
      sessionStorage.setItem('flight-contrate-tarifa-edit', true)
      this.$router.push({ name: 'flights-contrate-update' })
    },
    editItemContrate(iten) {
      this.$store.state.app.loadEscalas = true
      sessionStorage.removeItem('flight-contrate-show')
      sessionStorage.setItem('flight-contrate-id', iten.id)
      sessionStorage.setItem('flight-contrate-tarifa-edit', true)
      this.$router.push({ name: 'flights-contrate-update' })
    },
    seeItem(iten) {
      sessionStorage.setItem('flight-contrate-id', iten.id)
      sessionStorage.setItem('flight-contrate-show', true)

      this.$router.push({ name: 'flights-contrate-update' })
    },
    cloneItem(iten, posTarifa) {
      // console.log(iten)
      this.$store.state.app.loadEscalas = true
      sessionStorage.setItem('flight-contrate-id', iten.id)
      sessionStorage.setItem('flight-contrate-pos-tarifa', posTarifa)

      sessionStorage.removeItem('flight-contrate-tarifa-edit')

      sessionStorage.setItem('flight-aerolinea-id', iten.flight.aerolinea_id)
      sessionStorage.setItem('flight-numero-vuelo', iten.flight.numero_de_vuelo)

      this.$router.push({ name: 'flights-contrate-update' })
    },
    deleteItem(iten, posTarifa) {
      this.isDialogVisibleDelete = true
      this.itemId = iten.id
      this.itemPosTarifa = posTarifa
    },
    confirmDelete() {
      this.axios
        .delete(`contrate_flights/destroy-tarifa/${this.itemId}/${this.itemPosTarifa}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.isDialogVisibleDeleteContrate = false
      this.item = {}
      this.itemId = null
      this.itemPosTarifa = null
      this.loading = false
      this.load()
    },
    menorPrice(tarifas, type) {
      const prices = []
      if (type === 'price_adult') {
        tarifas.forEach(element => {
          prices.push(element.price_adult)
        })
      } else if (type === 'price_child') {
        tarifas.forEach(element => {
          prices.push(element.price_child)
        })
      }

      return this.sortedArray(prices)[0]
    },
    sortedArray(arrays) {
      return arrays.sort((a, b) => a - b)
    },
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },

    checkIn(frecuencias, flight) {
      const frecuenc = flight.flight.frecuencia
      // eslint-disable-next-line camelcase
      const check_in = []
      // eslint-disable-next-line camelcase
      const check_in_obj = []
      if (frecuencias.modelSemanas.length > 0) {
        frecuencias.modelSemanas.forEach(modelS => {
          frecuenc.forEach(element => {
            const frecs = element.frecuencia
            if (frecs.includes(modelS)) {
              check_in.push(element.origen.salida)
              check_in_obj.push({ dia: modelS, hora: element.origen.salida })

              // if (!check_in.includes(element.check_in)) { }
            }
          })
        })

        // eslint-disable-next-line camelcase
        return check_in_obj
      }

      check_in_obj.push({ dia: 'Buscar', hora: 'Buscar' })

      // eslint-disable-next-line camelcase
      return check_in_obj
    },
    arrivalFrec(frecuencias, flight) {
      const frecuenc = flight.flight.frecuencia
      // eslint-disable-next-line camelcase
      const hour_arrival = []
      // eslint-disable-next-line camelcase
      const hour_arrival_obj = []
      if (frecuencias.modelSemanas.length > 0) {
        frecuencias.modelSemanas.forEach(modelS => {
          frecuenc.forEach(element => {
            const frecs = element.frecuencia
            if (frecs.includes(modelS)) {
              hour_arrival.push(element.destino.llegada)
              hour_arrival_obj.push({ dia: modelS, hora: element.destino.llegada })

              // if (!check_in.includes(element.check_in)) { }
            }
          })
        })

        // eslint-disable-next-line camelcase
        return hour_arrival_obj
      }

      hour_arrival_obj.push({ dia: 'Buscar', hora: 'Buscar' })

      // eslint-disable-next-line camelcase
      return hour_arrival_obj
    },

    clearSearch() {
      this.model = {}
      this.load()
    },

    changeAerolinea() {
      const air = this.aerolineas.filter(a => a.id === this.model.aerolinea_id)[0]
      this.model.numero_de_vuelo = `${air.slug} `
      this.$refs.numero_de_vuelo.focus()

      // this.numeros_vuelos = air.numero_vuelos_validos
    },

    deleteContrateItem(iten) {
      this.isDialogVisibleDeleteContrate = true
      this.itemId = iten.id
    },
    confirmDeleteContrate() {
      this.axios
        .delete(`contrate_flights/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
